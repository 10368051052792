<section *ngIf="data" class="card__container card__container--tile mx-3">
  <cl-image *ngIf="data.Image?.Src"
    class="w-100 h-75"
    public-id="{{data.Image.Src}}"
    format="auto"
    quality="auto"
    width="auto"
    type="fetch"
    crop="fill"
    [attr.alt]="data.Image.Alt || ''"
    [attr.aspect-ratio]="1">
  </cl-image>
  <div class="d-flex align-items-center {{data.CTA_Alignment | alignmentClass}} h-25">
    <a *ngIf="data.CTA_Page_Item_Ref?.Page_Url"
      class="btn {{data.CTA_Style | buttonStyle}}"
      [routerLink]="['../' + data.CTA_Page_Item_Ref?.Page_Url]">
      {{data.CTA_Text}}
    </a>
  </div>
</section>
