<section *ngIf="(qmi$ | async) as qmi"
          class="PlanLocation p-2 mx-5"
          [@fadeInOnEnter]>
  <h4 *ngIf="data.Header_Text" class="PlanLocation__header-text text-left pt-2">{{data.Header_Text}}</h4>
  <div *ngIf="qmi.Address" class="PlanLocation__address text-left">
    <span>{{qmi.Address.Street1}} {{qmi.Address.Street2}}</span>
    <br/>
    <span>{{qmi.Address.City}}, {{qmi.Address.State}} {{qmi.Address.ZipCode}}</span>
  </div>
  <a *ngIf="data?.Homesite_CTA_Text && data.Homesite_CTA_Page_Item_Ref?.Page_Url"
      class="btn {{data.Homesite_CTA_Style | buttonStyle}} mt-4 mx-1 d-flex align-items-center"
      data-analytics-cta="Find A Homesite"
      [routerLink]="['../../' + data.Homesite_CTA_Page_Item_Ref?.Page_Url]"
      [queryParams]="{planId:qmi.PlanId, lotId:qmi.LotBlock}">
      {{data.Homesite_CTA_Text}}
  </a>
</section>
